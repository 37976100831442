import PropTypes from 'prop-types';
import style from './ToolTip.module.scss';
import { usePopper } from 'react-popper';
import { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

const DIRECTION = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
};

const ToolTip = ({
  children,
  text,
  customClassName = '',
  customTextClassName = '',
  customContainerClassName = '',
  direction = DIRECTION.BOTTOM,
  isArrowHidden = false,
  //we have some links in the tooltip should be clickable,
  //so we need to have delay time when we shift from the icon to the tooltip content
  tooltipClosingDelayMs = 0,
  hasNoHoveringDelay = false
}) => {
  const className = 'c-ToolTip';

  const isTooltipOnRef = useRef(false);
  const hoveringRef = useRef(false);
  const hoveringTimerRef = useRef(null);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isTooltipOn, setIsTooltipOn] = useState(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: direction,
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10]
          }
        }
      ]
    }
  );

  const contentClassName = [
    style[`${className}__content`],
    style[`${className}__content--${direction}`]
  ].join(' ');

  const arrowClassName = [
    style[`${className}__arrow`],
    style[`${className}__arrow--${direction}`]
  ].join(' ');

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = (isNoTimer) => () => {
    if (isNoTimer || hasNoHoveringDelay) {
      setIsHovering(true);
      return;
    }

    if (hoveringRef.current === false) {
      clearTimeout(hoveringTimerRef.current);
    }

    hoveringRef.current = true;
    hoveringTimerRef.current = setTimeout(() => {
      if (hoveringRef.current) {
        setIsHovering(true);
      }
    }, 300);
  };

  const handleMouseLeave = (isNoTimer) => () => {
    if (isNoTimer) {
      setIsHovering(false);
      return;
    }

    clearTimeout(hoveringTimerRef.current);
    hoveringRef.current = false;
    setIsHovering(false);
  };

  const cleanUpMouseEvents = () => {
    if (referenceElement) {
      referenceElement.removeEventListener(
        'mouseenter',
        handleMouseEnter()
      );
      referenceElement.removeEventListener(
        'mouseleave',
        handleMouseLeave()
      );
    }
    if (popperElement) {
      popperElement.removeEventListener(
        'mouseenter',
        handleMouseEnter(true)
      );
      popperElement.removeEventListener(
        'mouseleave',
        handleMouseLeave(true)
      );
    }
  };

  const populateMouseEvents = () => {
    if (referenceElement) {
      referenceElement.addEventListener('mouseenter', handleMouseEnter());
      referenceElement.addEventListener('mouseleave', handleMouseLeave());
    }
    if (popperElement) {
      popperElement.addEventListener('mouseenter', handleMouseEnter(true));
      popperElement.addEventListener('mouseleave', handleMouseLeave(true));
    }
  };

  useEffect(() => {
    cleanUpMouseEvents();
    populateMouseEvents();

    return cleanUpMouseEvents;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceElement, popperElement]);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      className={`${style[className]} relative ${
        customContainerClassName ?? ''
      }`}
      ref={setReferenceElement}
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      onMouseOver={() => {
        setIsTooltipOn(true);
      }}
      onMouseLeave={() => {
        const mouseLeave = () => {
          if (!isTooltipOnRef.current) {
            setIsTooltipOn(false);
          } else {
            isTooltipOnRef.current = false;
          }
        };

        if (tooltipClosingDelayMs) {
          setTimeout(mouseLeave, tooltipClosingDelayMs);
        } else {
          mouseLeave();
        }
      }}>
      {children}
      {(isTooltipOn || isHovering) &&
        createPortal(
          // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
          <div
            className={`${contentClassName} rounded-8 bg-dark-2c px-12 py-8 ${customClassName}`}
            style={styles.popper}
            ref={setPopperElement}
            {...attributes.popper}
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            onMouseOver={() => {
              setIsTooltipOn(true);
              isTooltipOnRef.current = true;
            }}
            onMouseLeave={() => {
              setIsTooltipOn(false);
            }}>
            {!isArrowHidden && <div className={arrowClassName}></div>}
            <div
              className={`text-label-md font-normal text-white-default ${customTextClassName}`}>
              {typeof text === 'function' ? text() : text}
            </div>
          </div>,
          document.getElementById('tooltip')
        )}
    </div>
  );
};

ToolTip.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func
  ]),
  direction: PropTypes.oneOf(Object.values(DIRECTION))
};

export default ToolTip;
