import { copyToClipboard } from '@/utility/helpers';
import { t } from '@/utility/localization';
import React from 'react';
import Icon from '../npl/Icon';
import { showToast } from './ToastContainer';
import NplToolTip from './NplToolTip';
import classNames from 'classnames';

const CopyIconWithTooltip = ({
  textToCopy,
  customCTA = null,
  toastText = null,
  isTertiaryColor = false
}) => {
  const onCopyClick = () => {
    copyToClipboard(textToCopy);
    showToast({ text: toastText ? toastText : t('copied') });

    if (customCTA) {
      customCTA();
    }
  };

  return (
    <NplToolTip text={t('copy')}>
      <div
        role="button"
        tabIndex={0}
        className="flex-shrink-0"
        onClick={onCopyClick}>
        <Icon
          name="copy"
          width="16"
          height="16"
          className={classNames(
            'fill-npl-text-icon-on-light-surface-secondary',
            {
              'fill-npl-text-icon-on-light-surface-tertiary':
                isTertiaryColor
            }
          )}
        />
      </div>
    </NplToolTip>
  );
};

export default CopyIconWithTooltip;
